import React from "react";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import icahslyLogo from "../assets/icashly_logo.png";
import esg from "../assets/esg.png";
import icon1 from "../assets/icons/icona_meth-1.png";
import icon2 from "../assets/icons/icona_meth-2.png";
import icon3 from "../assets/icons/icona_meth-3.png";
import icon4 from "../assets/icons/icona_meth-4.png";
import icon5 from "../assets/icons/icona_meth-5.png";
import laRepubblicaLogo from "../assets/photos/logo-repubblica.png";
import mondoefinanzaLogo from "../assets/photos/mondoefinanza_logo_2x.png";

import "../css/Home.css";

function Home() {
  const { t } = useTranslation();

  return (
    <div id="home">
      <div className="hero-home">
        {/*<img src={heroHome} alt="Home hero" />*/}
        <div className="text-hero-home">
          <div className="text">THE SIMPLER THE FINANCE, THE BETTER.</div>
        </div>
      </div>
      <div className="wrapper-content-home">
        <div className="container text-center pt-5 pb-4">
          <p className="rigsave-custom-title">{t("main.home.cosaOffriamo")}</p>
          <p className="text-home-font-size pb-4">
            {t("main.home.textCosaOffriamo")}
          </p>
          <div className="d-lg-flex justify-content-center">
            <div className="col-lg-3 wrapper-offers">
              <p>{t("main.home.da50")}</p>
              <div className="button-offers mx-4">
                <Link
                  to={"/fund-management"}
                  className="list-inline d-block text-center"
                >
                  {t("main.header.servicesDropdown.fundManagement")}
                </Link>
              </div>
            </div>
            <div className="col-lg-3 wrapper-offers">
              <p>{t("main.home.da1000")}</p>
              <div className="button-offers mx-4">
                <Link
                  to={"/certificates"}
                  className="list-inline d-block text-center"
                >
                  {t("main.header.servicesDropdown.certificates")}
                </Link>
              </div>
            </div>
            <div className="col-lg-3 wrapper-offers">
              <p>{t("main.home.da10")}</p>
              <div className="button-offers mx-4">
                <Link
                  to={"/financial-consulting"}
                  className="list-inline d-block text-center"
                >
                  {t("main.header.servicesDropdown.consulting")}
                </Link>
              </div>
            </div>
            <div className="col-lg-3 wrapper-offers">
              <p>{t("main.home.da250")}</p>
              <div className="button-offers mx-4">
                <Link
                  to={"/asset-management"}
                  className="list-inline d-block text-center"
                >
                  {t("main.header.servicesDropdown.gestionePat")}
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="container justify-content-center wrapper-methodology">
          <p className="rigsave-custom-title text-center pb-3">
            {t("main.home.ourMethodology")}
          </p>
          <div className="wrapper-meths">
            <div className="d-lg-flex wrapper-text-methodology">
              <div className="col-lg-2">
                <img
                  className="methodology-icon"
                  src={icon1}
                  alt="Valutazione patrimoniale"
                />
              </div>
              <div className="col-lg-3">
                <img
                  className="methodology-icon"
                  src={icon2}
                  alt="Valutazione"
                />
              </div>
              <div className="col-lg-3">
                <img className="methodology-icon" src={icon3} alt="Proposta" />
              </div>
              <div className="col-lg-2">
                <img
                  className="methodology-icon"
                  src={icon4}
                  alt="Investimento del capitale"
                />
              </div>
              <div className="col-lg-2">
                <img
                  className="methodology-icon"
                  src={icon5}
                  alt="Monitoraggio continuo"
                />
              </div>
            </div>
            <div className="d-lg-flex wrapper-text-methodology-2">
              <div className="col-lg-2 text-center">
                <p>{t("main.home.assetValue")}</p>
              </div>
              <div className="col-lg-3 valuation">
                <div className="wrapper-text-valuation">
                  <p className="value-title">
                    {t("main.home.value")}
                    {":"}
                  </p>
                  <div className="text-start">
                    <span>{t("main.home.orizzTemp")}</span>
                    <span>{t("main.home.riskManag")}</span>
                    <span>{t("main.home.capitalInv")}</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 text-center">
                <p>{t("main.home.propInv")}</p>
              </div>
              <div className="col-lg-2 text-center">
                <p>{t("main.home.invCap")}</p>
              </div>
              <div className="col-lg-2 text-center">
                <p>{t("main.home.monitoraggio")}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="container justify-content-center container-invest pb-5 pt-3">
          <p className="rigsave-custom-title text-center pb-3">
            {t("main.home.investWithUs")}
          </p>
          <div className="wrapper-container-invest">
            <div className="d-lg-flex justify-content-center wrapper-divs-invest">
              <div className="col-lg-4 invest mx-3">
                <p className="title-invest">
                  {t("main.home.finanzaSempliceTitle")}
                </p>
                <p className="text-invest">{t("main.home.finanzaSemplice")}</p>
              </div>
              <div className="col-lg-4 invest mx-3">
                <p className="title-invest">
                  {t("main.home.prezziVantaggiosiTitle")}
                </p>
                <p className="text-invest">
                  {t("main.home.prezziVantaggiosi")}
                </p>
              </div>
              <div className="col-lg-4 invest mx-3">
                <p className="title-invest">
                  {t("main.home.rapportoDirettoTitle")}
                </p>
                <p className="text-invest">{t("main.home.rapportoDiretto")}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="container justify-content-center container-icashly">
          <div className="wrapper-image-icashly text-center">
            <img width={350} height={65} src={icahslyLogo} alt="iCashly" />
            <p className="pb-4 pt-4 text-home-font-size">
              {t("main.home.icashly_text")}
            </p>
            <Link
              to={"/contact-us"}
              className="list-inline d-block text-center"
            >
              {t("main.home.contactUs")}
            </Link>
          </div>
        </div>
        <div className="container justify-content-center container-news pt-5 pb-5 mt-4">
          <p className="rigsave-custom-title text-center pb-4">
            {t("main.home.aboutUs")}
          </p>
          <div className="d-lg-flex">
            <div className="news col-lg-6 me-2">
              <img
                src={laRepubblicaLogo}
                className="pb-5"
                alt="LaRepubblica logo"
              />
              <Link
                to={
                  "https://finanza.repubblica.it/Pages/News/Item.aspx?ID=93_2023-12-18_TLB"
                }
                className="list-inline d-block text-center"
              >
                {t("main.home.read")}
              </Link>
            </div>
            <div className="news col-lg-6 ms-2">
              <img
                src={mondoefinanzaLogo}
                className="pb-5"
                alt="Mondoefinanza logo"
              />
              <Link
                to={
                  "https://www.mondoefinanza.it/2020/02/01/rigsave-costituisce-la-spa-holding-del-gruppo-per-la-futura-quotazione-in-borsa-valori/"
                }
                className="list-inline d-block text-center"
              >
                {t("main.home.read")}
              </Link>
            </div>
          </div>
        </div>
        <div className="wrapper-sustainability pt-5 mb-5">
          <div className="text-hero-home">
            <div className="text pb-5">
              <p>{t("main.home.sustainability")}</p>
              <p className="text-start">{t("main.home.sustainabilityText")}</p>
              <p className="text-start">{t("main.home.sustainabilityText2")}</p>
            </div>
            <div className="wrapper-esg text-center">
              <img width="100" src={esg} alt="ESG logo" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
