import React from "react";

import { useTranslation } from "react-i18next";

import "../css/ContactUs.css";
import { Link } from "react-router-dom";

import icon1 from "../assets/icons/icona_meth-3.png";
import icon2 from "../assets/icons/icon_contact_2.png";
import icon3 from "../assets/icons/icon_contact_3.png";

function ContactUs() {
  const { t } = useTranslation();

  return (
    <div id="contactUs" className="pt-5 mt-5 pb-5 mb-5">
      <div className="pt-5 pb-5">
        <div className="rigsave-custom-title text-center">
          {t("main.contactUsPage.title")}
        </div>
      </div>
      <div className="container d-lg-flex justify-content-center pb-5 mb-5">
        <div className="col-lg-4 ps-5">
          <img src={icon3} alt="Contacts us icon" />
          <h3 className="pt-3 pb-2 m-0">{t("main.contactUsPage.contactUs")}</h3>
          <Link
            className="link-mail"
            to={void 0}
            onClick={(e) =>
              (window.location.href = "mailto:info@rigsavecapital.com")
            }
          >
            info@rigsavecapital.com
          </Link>
          <p className="pt-3 pb-3">(+39) 03 0375 6520</p>
        </div>
        <div className="col-lg-4 px-5">
          <img src={icon2} alt="Send CV icon" />
          <h3 className="pt-3 m-0">{t("main.contactUsPage.sendCvTitle")}</h3>
          <p className="pt-2 pb-3">{t("main.contactUsPage.sendCvText")}</p>
        </div>
        <div className="col-lg-4 pe-5">
          <img src={icon1} alt="Find us icon" />
          <h3 className="pt-3 m-0">{t("main.contactUsPage.letsFindUs")}</h3>
          <p className="pt-2 pb-3">C.so Giuseppe Zanardelli 38, Brescia (BS)</p>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
